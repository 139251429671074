<h2 mat-dialog-title>{{ "areYouSure" | translate }}</h2>
<div mat-dialog-content class="!pb-0 w-80">
  <p>{{ "pleaseConfirmAction" | translate }}</p>
</div>
<mat-dialog-actions class="bg-gray-50 !px-4 !py-4 !justify-between">
  <button mat-flat-button [mat-dialog-close]="false">
    {{ "cancel" | translate }}
  </button>
  <button
    mat-flat-button
    [mat-dialog-close]="true"
    color="primary"
    cdkFocusInitial
  >
    {{ "confirm" | translate }}
  </button>
</mat-dialog-actions>
