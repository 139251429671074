/* eslint-disable @typescript-eslint/no-explicit-any */
import { Directive, OnDestroy, inject } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subscription,
  catchError,
  of,
} from 'rxjs';
import { UiService } from '../services/ui.service';

@Directive()
export class BaseComponent implements OnDestroy {
  loading$: Observable<boolean>;
  ui!: UiService;

  private loading = new BehaviorSubject<boolean>(false);
  private subs: Subscription[] = [];

  constructor() {
    this.ui = inject(UiService);
    this.loading$ = this.loading;
  }

  ngOnDestroy() {
    this.unsubscribe();
  }

  toggleLoading(state: boolean) {
    this.loading.next(state);
    this.ui.toggleLoading(state);
  }

  protected async execSafe<T>(
    exec: () => Promise<T>,
    config?: {
      silent?: boolean;
      hideLoading?: boolean;
      errorMessage?: string;
    }
  ): Promise<T | undefined> {
    try {
      this.toggleLoading(!config?.hideLoading);
      return await exec();
    } catch (err) {
      console.error(err);
      if (!config?.silent) {
        this.ui.showErrorMessage(config?.errorMessage);
      }
      return undefined;
    } finally {
      this.toggleLoading(false);
    }
  }

  protected subSafe(
    stream: Observable<unknown>,
    next?: (...params: any) => void
  ) {
    this.subs.push(
      stream
        .pipe(
          catchError((error) => {
            console.error(error);
            this.ui.showErrorMessage();
            return of(error);
          })
        )
        .subscribe((params) => (next ? next(params) : undefined))
    );
  }

  protected unsubscribe() {
    for (const sub of this.subs) {
      sub.unsubscribe();
    }
  }
}
